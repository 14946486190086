* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

html, body {
  color: #fff;
  background-color: #000;
  overflow-x: hidden;
}

.main-container {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
}

.background-video {
  object-fit: cover;
  z-index: 0;
  opacity: .5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar {
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
  display: flex;
  position: fixed;
}

.logo {
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 40px;
  font-family: Space Grotesk, sans-serif;
  font-size: 32px;
}

.navlinks {
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-right: 40px;
  font-family: Space Grotesk, sans-serif;
  font-size: 24px;
  display: flex;
}

.nav-items {
  text-decoration: none;
}

.nav-items:hover {
  cursor: pointer;
  text-underline-offset: 5px;
  text-decoration: underline #fff;
}

.content {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 100%;
  margin-top: 30vh;
  padding-left: 40px;
  display: flex;
  position: relative;
}

.content-subtitle {
  z-index: 1;
  color: #fff;
  width: 55%;
  font-family: Space Grotesk, sans-serif;
  font-size: 60px;
  font-weight: 500;
}

.content-title {
  z-index: 1;
}

.content-title-image {
  width: 98.5%;
  padding-bottom: 100px;
}

.content-subtitle-button {
  color: #000;
  cursor: pointer;
  text-align: left;
  background: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 20px;
  font-family: Space Grotesk, sans-serif;
  font-size: 24px;
}

.content-subtitle-button:hover {
  color: #fff;
  background: #000;
}

.about-section {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
}

.about-section-title {
  z-index: 1;
  color: #fff;
  width: 50%;
  font-family: Space Grotesk, sans-serif;
  font-size: 60px;
}

.about-content {
  z-index: 1;
  color: #000;
  width: 50%;
  padding-left: 20px;
  font-family: Space Grotesk, sans-serif;
  font-size: 40px;
}

.about-section-image-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 50%;
  display: flex;
}

.about-section-image {
  width: 45%;
  height: auto;
}

.image-split-section {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.image-split-section-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.services-section {
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

.services-section-content {
  z-index: 1;
  color: #fff;
  width: 50%;
  padding-left: 20px;
  font-family: Space Grotesk, sans-serif;
  font-size: 40px;
}

.services-section-video {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.services-section-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contact-section {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
}

.contact-section-content {
  z-index: 1;
  color: #000;
  width: 100%;
  padding-left: 20px;
  font-family: Space Grotesk, sans-serif;
  font-size: 40px;
}

.contact-section-links {
  padding-top: 20px;
}

.contact-section-text {
  z-index: 1;
  color: #000;
  width: 55%;
  padding-left: 20px;
  font-family: Space Grotesk, sans-serif;
  font-size: 40px;
  font-weight: 500;
}

.contact-section-link {
  z-index: 1;
  color: #000;
  padding-left: 20px;
  font-family: Space Grotesk, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.contact-section-image-container {
  width: 100%;
  height: 100%;
}

.contact-section-image {
  width: 98%;
  height: 100%;
}

#contact-section-title {
  padding-top: 20vh;
  padding-left: 40px;
  padding-right: 20px;
}

.navbar.hidden {
  display: none;
}

.hamburger {
  cursor: pointer;
  z-index: 3;
  margin-right: 20px;
  display: none;
}

.line {
  background: #fff;
  border-radius: 2px;
  width: 30px;
  height: 3px;
  margin: 2px;
  transition: all .3s;
}

.mobile-nav {
  z-index: 10;
  background: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.mobile-nav-items {
  color: #fff;
  cursor: pointer;
  margin: 20px 0;
  font-family: Space Grotesk, sans-serif;
  font-size: 32px;
}

.close-btn {
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  position: absolute;
  top: 20px;
  right: 30px;
}

@media screen and (width >= 390px) and (width <= 767px) {
  * {
    overflow-x: hidden;
  }

  .content {
    padding: 20px;
  }

  .content-subtitle {
    width: 100%;
    font-size: 32px;
  }

  p {
    margin-bottom: 10px;
  }

  .content-subtitle-button {
    font-size: 16px;
  }

  .about-content {
    width: 100%;
    font-size: 20px;
  }

  .about-section {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    font-size: 20px;
    display: flex;
  }

  .about-section-image {
    width: 42%;
    margin-top: 20px;
  }

  .about-section-image-container {
    width: 100%;
  }

  .services-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .services-section-video {
    width: 100%;
    height: 30%;
    margin-top: 20vh;
  }

  .services-section-content {
    width: 100%;
    height: 70%;
    margin-top: 20px;
    padding-left: 0;
    font-size: 20px;
  }

  .contact-section-text {
    width: 98%;
    padding-left: 0;
    font-size: 20px;
  }

  #contact-section-title {
    padding-left: 20px;
  }

  .contact-section-link {
    padding-left: 0;
  }

  .desktop-nav {
    display: none;
  }

  .hamburger {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    display: flex;
  }

  .mobile-nav {
    z-index: 2;
    display: none;
  }

  .logo {
    margin-left: 20px;
  }
}

@media screen and (width >= 1400px) {
  .content {
    margin-top: 25vh;
  }

  .content-subtitle {
    width: 45%;
    font-size: 40px;
  }

  .content-subtitle-button {
    font-size: 20px;
  }

  .about-content {
    font-size: 32px;
  }

  p {
    margin-bottom: 20px;
  }

  .services-section-content {
    font-size: 32px;
  }

  .content-title-image {
    padding-bottom: 60px;
    padding-right: 20px;
  }

  .contact-section-text {
    font-size: 32px;
  }
}

@media screen and (width >= 2000px) {
  .content-subtitle {
    width: 40%;
    font-size: 60px;
  }

  .about-content, .services-section-content {
    font-size: 48px;
  }

  .contact-section-text {
    font-size: 60px;
  }

  #contact-section-title {
    padding-top: 25vh;
  }
}
/*# sourceMappingURL=index.a0a5ea9c.css.map */
